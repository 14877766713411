import { render, staticRenderFns } from "./Slider.vue?vue&type=template&id=783f57c9"
import script from "./Slider.vue?vue&type=script&lang=js"
export * from "./Slider.vue?vue&type=script&lang=js"
import style0 from "./Slider.vue?vue&type=style&index=0&id=783f57c9&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VCard,VCardText,VSlider,VSubheader})
